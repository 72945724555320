<template>
  <div>
    <div class="status-offline" :class="{show:$store.state.offline}">Режим работы без сети</div>
    <div class="status-sync" :class="{show:sync}">Синхронизация</div>
    <div class="status-socket" :class="status">
      {{ text }}
      <div class="log" :class="{anim}">
        {{ log }}
        <strong>{{ update }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex"
//import Echo from 'laravel-echo';
//import Pusher from 'pusher-js';

// window.socket = new Echo({
//     broadcaster: 'pusher',
//     key: 'opspot',
//     authEndpoint: window.url + '/broadcasting/auth',
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     wssPort: 6001,
//     disableStats: true,
//     enabledTransports: ['ws', 'wss'],
//     forceTLS: false,
// });

export default {
  setup() {
    let sync = ref(false);
    const store = useStore();
    const value = computed(() => store.state.offline)
    watch(value, async (oldState, newState) => {
      if(!oldState && newState) {
        sync.value = true
        await api.syncDB()
        sync.value = false
      }
    })

    let status = ref('offline');
    let text = ref('offline');
    let update = ref('');
    let log = ref('');
    let anim = ref(false);
    // let timer = null

    // socket.connector.pusher.connection.bind('connecting', () => {
    //   status.value = 'wait';
    //   text.value = 'connecting...'
    // });
    //
    // socket.connector.pusher.connection.bind('connected', () => {
    //   status.value = text.value = 'online';
    // });
    //
    // socket.connector.pusher.connection.bind('disconnected', () => {
    //   status.value = text.value = 'offline';
    // });
    //
    // socket.channel('logger').listen('LoggerSocket', ({message}) => {
    //   console.log('listen.StatusSocket.LoggerSocket')
    //
    //   const time = new Date()
    //   update.value = time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds() + '.' + time.getMilliseconds()
    //   log.value = message
    //
    //   anim.value = true
    //   clearTimeout(timer)
    //   timer = setTimeout(() => {
    //     anim.value = false
    //   }, 3000)
    // })

    return {
      status, text, log, update, anim, sync
    }
  }
}
</script>

<style scoped lang="less">
.status-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  background: darkred;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  height: 0;
  transition: 300ms;
  opacity: 0;
  &.show {
    opacity: 1;
    height: 15px;
  }
}
.status-sync {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007aff;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  height: 0;
  transition: 300ms;
  opacity: 0;
  &.show {
    opacity: 1;
    height: 15px;
  }
}
.status-socket {
  display: none;

  position: fixed;
  top: 0;
  right: 0;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
  background: darkred;
  color: #fff;

  &.online {
    background: green;
  }

  &.wait {
    background: darkorange;
  }
}

.log {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;

  background: #c0c0c0;
  padding: 10px;
  font-size: 8px;
  color: #000;
  transition: 300ms;
  transform: translateY(100%);

  &.anim {
    transform: translateY(0);
  }

  strong {
    position: absolute;
    top: -10px;
    right: 5px;
  }
}
</style>
