<template>
  <div class="nav-header" v-if="title">
    <button @click="$router.push(back)">Назад</button>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    title: null,
    back: null
  }
}
</script>

<style lang="less" scoped>
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;

  h1 {
    margin: 0;
    line-height: 1;
    font-size: 22px;
  }
}
</style>
