<template>
  <div class="footer">
    OpSpot.Sklad © 2021 (ver {{version}})
  </div>
</template>

<script>
import pkg from '../../package.json'

export default {
  setup() {
    const version = pkg.version
    return {
      version
    }
  }
}
</script>

<style lang="less">
  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
    opacity: 0.5;
    font-size: 12px;
  }
</style>
