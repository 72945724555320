import {createRouter, createWebHashHistory} from 'vue-router'
import Home from '../views/Index'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../views/Goods')
  },
  {
    path: '/link_code',
    name: 'LinkCode',
    component: () => import('../views/LinkCode/Index')
  },
  {
    path: '/link_code/by_code',
    name: 'LinkCodeByCode',
    component: () => import('../views/LinkCode/ByCode')
  },
  {
    path: '/link_code/by_goods',
    name: 'LinkCodeByGoods',
    component: () => import('../views/LinkCode/ByGoods')
  },
  {
    path: '/supplies',
    name: 'Supplies',
    component: () => import('../views/Supplies/Index')
  },
  {
    path: '/supplies/:id',
    name: 'Supply',
    component: () => import('../views/Supplies/Supply')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders/Index')
  },
  {
    path: '/orders/:id',
    name: 'Order',
    component: () => import('../views/Orders/Order')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
