<template>
  <StatusSocket/>

  <NavHeader :title="nav.title" :back="nav.back"/>
  <router-view @meta="val => nav = val"/>
  <Footer/>
  <DrawerNewVersion/>

</template>

<script>
import StatusSocket from "@/components/StatusSocket";
import Footer from "@/components/Footer";
import NavHeader from "@/components/NavHeader";
import {ref} from 'vue'
import DrawerNewVersion from "@/components/DrawerNewVersion";

export default {
  components: {DrawerNewVersion, NavHeader, Footer, StatusSocket},
  setup() {
    let nav = ref({
      title: null,
      back: null
    })
    return {
      nav
    }
  }
}

</script>

<style lang="less">
@import "./assets/normalize.css";

* {
  box-sizing: border-box;
}


body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.overflow {
    overflow: hidden;
  }
}

#app {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 960px;
  min-height: calc(100vh - 20px);
  margin: 0 auto;
  padding-bottom: 50px;
}

input, select {
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin: 0 10px;
  max-width: 960px;
  font-size: 16px;
  border: 1px solid #999;
  padding: 0 10px;
  line-height: 40px;
  height: 40px;
  outline: none;

  &:focus {
    border-color: #007aff;
  }

  &:disabled {
    opacity: 0.5;
  }
}

button {
  height: 40px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &.full {
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}

table {
  margin: 10px;
  width: calc(100% - 20px);

  th, td {
    font-size: 14px;
    padding: 5px 10px;
    background: #eee;
  }

  th {
    text-align: center;
  }

  td:last-child {
    text-align: center;
  }

  .num {
    font-size: 10px;
    text-align: center;
  }
}

.text-center {
  text-align: center;
}

.text-red {
  color: red;
}

</style>
