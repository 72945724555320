<template>
  <div class="loader" v-show="state">
    <div v-if="typeof state === 'string'">{{ state }}</div>
  </div>
</template>

<script>
export default {
  props: {
    state: null
  }
}
</script>

<style lang="less" scoped>
.loader {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  margin: 30px 10px;
  height: 30px;
  border: 1px solid #007aff;
  overflow: hidden;
  div {
    position: relative;
    z-index: 1;
    color: #007aff;
  }

  @keyframes anim {
    from { left: -50px }
    to { left:  calc(100% + 10px) }
  }

  &:before {
    position: absolute;
    z-index: 0;
    content: '';
    top: 0;
    left: -50px;
    bottom: 0;
    width: 40px;
    opacity: 0.3;
    background: #007aff;
    transform: skew(-45deg);
    animation: anim 1s infinite;
  }
}
</style>
