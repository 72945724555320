import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import api from './api/resource'

window.url = process.env.VUE_APP_API_URL
axios.defaults.baseURL = window.url + '/api/'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
window.axios = axios
window.api = api

createApp(App).use(store).use(router).mount('#app')
