<template>
  <div class="drawer-new-version" :class="{show}">
    <div>
      <template v-if="!load">
        Доступна новая версия, обновить?<br>
        <button @click="show = false">Позже</button>
        <button @click="refresh">Обновить сейчас</button>
      </template>
      <Loader :state="load"/>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import Loader from "@/components/Loader";

export default {
  components: {Loader},
  setup() {
    let show = ref(false)
    let load = ref('')
    window.addEventListener('message', event => {
      if (event.data.type === 'service-worker' && event.data.data === 'new-version')
        show.value = true
    })

    const refresh = () => {
      load.value = 'Обновление...'
      newSW.postMessage({type: 'SKIP_WAITING'})
      window.location.reload()
    }

    return {
      show, refresh, load
    }
  }
}
</script>

<style lang="less">
.drawer-new-version {
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0 0 0 #555;
  transform: translateY(100%);

  > div {
    max-width: 960px;
    margin: 0 auto;
    padding: 10px;
  }

  &.show {
    box-shadow: 0 0 35px #555;
    transform: translateY(0);
  }

  button {
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
