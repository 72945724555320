<template>
  <div class="page-home">

    <button @click="$router.push('/goods')">Товары</button>
    <button @click="$router.push('/link_code')">Привязка штрихкодов</button>
    <button @click="$router.push('/supplies')" v-if="!$store.state.offline">Прием товара</button>
    <button @click="$router.push('/orders')" v-if="!$store.state.offline">Заказы</button>
    <button @click="$router.push('/settings')">Настройки</button>

<!--    <button @click="send('test success')">Test</button>-->
  </div>
</template>

<script>
export default {
  setup(props, {emit}) {
    emit('meta', {title: null})

    const send = (val) => {
      axios.post('test', {data: val})
    }

    return {
      send
    }
  }
}
</script>

<style lang="less">
.page-home {
  button {
    display: block;
    width: calc(100% - 20px);
    margin: 10px;
  }
}
</style>
