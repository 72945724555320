import Dexie from "dexie";
import store from "../store"
import axios from "axios";

const db = new Dexie('goodsdb')
db.version(2).stores({
  goods: '++id,name,code',
  barcodes: 'barcode'
});

export default {
  async clearGoodsLocal() {
    return db.goods.clear()
  },
  async syncDB() {
    const update = await db.goods.filter(goods => goods.update).toArray()
    if(update) {
      let goods = []
      update.map(g => { goods.push({id:g.id,barcodes:g.barcodes}) })
      const statusUpload = await axios.post('v1/goods/barcodes', {goods}).catch(() => {
        store.state.offline = true
      })
      if (!statusUpload) return
    }

    const count = await this.getGoodsCount('remote')
    const {data} = await axios.get('v1/goods', {params: {limit: count}})
    await db.goods.bulkPut(data.data)
    let barcodes = [];
    data.data.map(g => {
      g.barcodes.map(b => {
        barcodes.push({id:g.id, barcode: b.barcode})
      })
    })
    return db.barcodes.bulkPut(barcodes)
  },

  async getGoodsCount(source = 'local') {
    if (source === 'remote') {
      return await axios.get('v1/goods/count').then(res => {
        store.state.offline = false
        return res.data
      }).catch(() => {
        store.state.offline = true
      })
    } else {
      return db.goods.count()
    }
  },
  async getGoods(params) {
    if (!params.limit) params.limit = 20
    if (!params.page) params.page = 1

    return await axios.get('v1/goods', {params})
      .then(res => {
        store.state.offline = false
        return res.data
      })
      // .then(async () => {
      .catch(async () => {
        store.state.offline = true
        //   if(!error.response){
        let query = db.goods
        let query_barcode = db.barcodes
        if (params.search) {
          let search = new RegExp(params.search, "i");
          const barcode = await query_barcode.get(params.search)
          query = query.filter(goods => {
              return search.test(goods.name) || params.search === goods.code || (barcode && goods.id === barcode.id)
            }
          )
        }
        if (params.name) {
          let search = new RegExp(params.name, "i");
          query = query.filter(goods => search.test(goods.name))
        }
        if (params.codeEmpty) {
          query = query.filter(goods => !goods.barcodes.length)
        }
        const result = await query.offset(params.limit * params.page - params.limit).limit(params.limit).toArray()
        const total = await query.count()

        return {
          data: result,
          per_page: params.limit,
          current_page: params.page,
          total
        }
        // }
        // TODO ответ, если ошибка не в соединении
      })
  },
  async getGoodsFind(code) {
    return await axios.post('v1/goods/find', {code})
      .then(res => {
        store.state.offline = false
        return res.data
      })
      .catch(async () => {
        store.state.offline = true
        //   if(!error.response){
        const barcode = await db.barcodes.where('barcode').equals(code).first()
        let result = ''
        if(barcode) {
          result = await db.goods.where('id').equals(barcode.id).first()
        }
        return result
        // }
        // TODO ответ, если ошибка не в соединении
      })
  },
  async setGoods(params) {
    return await axios.post('v1/goods/' + params.id, {code: params.code, amount: params.amount})
      .then(() => {
        store.state.offline = false
        return 'success'
      }).catch(async () => {
        //   if(!error.response){
        store.state.offline = true
        const goods = await db.goods.where('id').equals(params.id).first()
        goods.barcodes.push({
          barcode: params.code,
          amount: params.amount
        })
        goods.update = true
        await db.barcodes.put({id:params.id, barcode: params.code})
        return await db.goods.put(goods)
          .then(() => {
            return 'success'
          }).catch(() => {
            return ''
          })
        // }
        // TODO ответ, если ошибка не в соединении
      })
  }
}
